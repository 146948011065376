<template>
  <div>
    <v-navigation-drawer app color="primary-darken-3" permanent theme="dark">
      <v-list class="!bg-transparent">
        <v-list-item
          v-for="section in navigation"
          :key="section.title"
          :title="section.title"
        >
          <app-menu-list :items="section.items" />
        </v-list-item>
      </v-list>

      <template #append>
        <div class="pb-2">
          <v-list-item prepend-icon="ph:gear" title="Settings" to="/settings" />
          <user-menu-entry />
        </div>
      </template>
    </v-navigation-drawer>

    <v-main>
      <nuxt-page />
    </v-main>
  </div>
</template>

<script lang="ts" setup>
import type { MenuListItem } from "@/shared/menu/menu.model";

const proposalToolEnabled = useFeatureFlag("proposal_tool");

const navigation: { title: string; items: MenuListItem[] }[] = [
  {
    title: "Operation center",
    items: [
      {
        label: "Organizations",
        icon: "ph:buildings",
        to: { name: "organizations" },
      },
      {
        label: "Sessions",
        icon: "ph:calendar",
        to: { name: "sessions" },
      },
      ...(proposalToolEnabled
        ? ([
            {
              label: "Proposals",
              icon: "ph:cash-register",
              to: { name: "proposals" },
            },
          ] as const)
        : []),
    ],
  },
  {
    title: "CMS",
    items: [
      {
        label: "Tags",
        icon: "ph:tag",
        to: { name: "tags-path" },
      },
      {
        label: "Tag pages",
        icon: "ph:browser",
        to: { name: "tag-pages" },
      },
      {
        label: "Teachers",
        icon: "ph:graduation-cap",
        to: { name: "teachers" },
      },
      {
        label: "Courses",
        icon: "ph:books",
        to: { name: "courses" },
      },
      {
        label: "Places",
        icon: "ph:map-pin",
        to: { name: "places" },
      },
      {
        label: "Companies",
        icon: "ph:buildings",
        to: { name: "companies" },
      },
      {
        label: "Emails",
        icon: "ph:envelope-simple",
        to: { name: "emails" },
      },
    ],
  },
];
</script>
